.intro-animation {
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(8, auto);
  width: 100%;
  z-index: -1;
}

.intro-animation div {
  height: 100vh;
  width: 0%;
  background-color: white;
  opacity: 0.2;
}
