.animation-paper-plane-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none !important;
}

.animation-paper-plane {
  overflow-x: hidden;
  height: 100vh;
  width: 100vw;
  position: relative;
  overflow: hidden;
}

.animation-paper-plane .paper-plane {
  position: absolute;
  top: -20%;
  left: -200px;
}

.paper-plane img {
  height: 100px;
  width: auto;
}

@media (max-width: 600px) {
  .animation-paper-plane-wrapper {
    display: none !important;
  }
}
