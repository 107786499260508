.skill-icon {
  height: 2.4rem;
  width: auto !important;
}

.skill-social-link-icon {
  color: black;
  font-size: 2.2rem;
  cursor: pointer;
  transition: var(--link-transition);
}

.skill-social-link-icon:hover {
  color: var(--link);
}

.projectImg {
  border: 3px solid black;
  width: 100%;
}

.darken-image {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
  background: black;
  opacity: 0.1;
  transition: opacity 200ms cubic-bezier(0.4, 0, 1, 1);
}

.darken-image:hover {
  opacity: 0;
}
